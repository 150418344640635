<template>
  <div class="card-home card-plan">
    <h1 class="title-card">{{ plan?.name }}</h1>
    <h2 class="subtitle-card">COP $ 0 Mesual</h2>
    <h3 class="mb-4">Primer mes gratis</h3>
    <div class="list-items">
      <div class="item">
        <img src="@/assets/images/checked.png" alt="Guiia Tour" />
        <h1><strong>{{ numberTotal(plan?.numberUsers, "1") }}</strong> (administradores, auxiliares, vendedores).</h1>
      </div>
      <div class="item">
        <img src="@/assets/images/checked.png" alt="Guiia Tour" />
        <h1><strong>{{ numberTotal(plan?.numberTransportation, "2") }}</strong> (embarcación, bus, etc).</h1>
      </div>
      <div class="item">
        <img src="@/assets/images/checked.png" alt="Guiia Tour" />
        <h1>Creación de tours ilimitados.</h1>
      </div>
      <div class="item">
        <img src="@/assets/images/checked.png" alt="Guiia Tour" />
        <h1>Ventas y reservas de tiquetes ilimitadas.</h1>
      </div>
      <div class="item">
        <img src="@/assets/images/checked.png" alt="Guiia Tour" />
        <h1>Generación de planillas (PDF) ilimitadas.</h1>
      </div>
      <div class="item">
        <img src="@/assets/images/checked.png" alt="Guiia Tour" />
        <h1>Creación de liquidaciones ilimitadas.</h1>
      </div>
    </div>
    <div class="mt-4">
      <button class="btn-nav btn-register w-100" @click.prevent="() => $emit('selectPlan', plan)">
        Elegir Plan
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    plan: {
      type: Object,
      default: () => {},
    }
  },
  methods: {
    numberTotal(value, type) {
      const name = type === "1" ? "Usuarios" : "Transportes";
      return value === "1000" ? `${name} ilimitado` : `${value} ${name}`;
    },
  }
};
</script>

<style lang="scss" scoped></style>
