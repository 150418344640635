<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row class="mb-2 content-register">
      <b-col cols="12">
        <div class="title-register text-center text-white">Datos de la Empresa</div>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="name" class="label_input">Nombre*</label>
          <b-form-input
            id="name"
            size="sm"
            placeholder="Nombre"
            v-model="formData.name"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="nit" class="label_input">Nit*</label>
          <b-form-input
            id="nit"
            size="sm"
            placeholder="Nit"
            v-model="$v.formData.nit.$model"
            @blur="$v.formData.nit.$touch()"
          />
          <span
            v-if="$v.formData.nit.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.nit) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Departamento*</label>
          <v-select
            :options="departments"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.departmentUuid"
            :reduce="(option) => option.uuid"
            @input="loadMunicipalitiesByDepart"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Municipio*</label>
          <v-select
            :options="municipalities"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.municipalityUuid"
            :reduce="(option) => option.uuid"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="cellphone_enterprise" class="label_input">N° de Celular con whatsapp*</label>
          <b-form-input
            id="cellphone_enterprise"
            size="sm"
            placeholder="Celular"
            v-model="$v.formData.cellphone_enterprise.$model"
            @blur="$v.formData.cellphone_enterprise.$touch()"
          />
          <span
            v-if="$v.formData.cellphone_enterprise.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.cellphone_enterprise) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="address" class="label_input">Dirección</label>
          <b-form-input
            id="address"
            size="sm"
            placeholder="Dirección"
            v-model="formData.address"
          />
        </div>
      </b-col>
      <b-col cols="12">
        <div class="title-register text-center text-white">
          Datos del Plan Seleccionado
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="total" class="label_input">Plan</label>
          <b-form-input
            id="total"
            size="sm"
            placeholder="Precio"
            :value="`${plan?.name} - Mensual`"
            disabled
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">N° de usuarios</label>
          <b-form-input
            size="sm"
            :value="plan.numberUsers"
            disabled
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">N° de transportes</label>
          <b-form-input
            size="sm"
            :value="plan.numberTransportation"
            disabled
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="total" class="label_input">Precio inicial del Plan</label>
          <b-form-input
            id="total"
            size="sm"
            placeholder="Precio"
            v-model="formData.total"
            disabled
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <label class="label_input">Fecha Inicial</label>
        <b-form-input size="sm" v-model="formData.initial_date" disabled />
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Fecha Final</label>
          <b-form-input size="sm" v-model="formData.final_date" disabled />
        </div>
      </b-col>
      <b-col cols="12">
        <div class="title-register text-center text-white">
          Datos del Propietario
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="names" class="label_input">Nombres*</label>
          <b-form-input
            id="names"
            size="sm"
            placeholder="Nombres"
            v-model="formData.names"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="last_names" class="label_input">Apellidos*</label>
          <b-form-input
            id="last_names"
            size="sm"
            placeholder="Apellidos"
            v-model="formData.last_names"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Tipo de identificación*</label>
          <v-select
            :options="typeIndetificatios"
            label="value"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.type_identification"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="identification" class="label_input"
            >Identificación*</label
          >
          <b-form-input
            id="identification"
            size="sm"
            placeholder="Identificación"
            v-model="$v.formData.identification.$model"
            @blur="$v.formData.identification.$touch()"
          />
          <span
            v-if="$v.formData.identification.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.identification) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="email" class="label_input">Correo electrónico*</label>
          <b-form-input
            id="email"
            type="email"
            size="sm"
            placeholder="Correo eléctronico"
            v-model="$v.formData.email.$model"
            @blur="$v.formData.email.$touch()"
          />
          <span
            v-if="$v.formData.email.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.email) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="cellphone" class="label_input">N° de Celular con whatsapp*</label>
          <b-form-input
            id="cellphone"
            size="sm"
            placeholder="Celular"
            v-model="$v.formData.cellphone.$model"
            @blur="$v.formData.cellphone.$touch()"
          />
          <span
            v-if="$v.formData.cellphone.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.cellphone) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Género</label>
          <v-select
            :options="typeGenders"
            label="value"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.gender"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Tipo de perfil</label>
          <b-form-input size="sm" value="Propietario" disabled />
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Registrar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import ActionCRUD from "@/mixins/ActionCRUDsp";
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import moment from "moment";
moment.locale("es");

import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  inject: ["authRepository", "geographyRepository"],
  mixins: [ActionCRUD],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
  },
  props: {
    plan: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      isBusy: false,
      formData: {
        names: "",
        last_names: "",
        email: "",
        profileUuid: "bcaa1cc2-72f3-4183-a36b-03e4c7d845ba",
        type_identification: "Cédula de ciudadanía",
        identification: "",
        cellphone: "",
        cellphone_enterprise: "",
        gender: null,
        name: "",
        nit: "",
        planEnterprisePriceUuid: this.plan?.uuid,
        initial_date: "",
        final_date: "",
        address: "",
        total: "0",
        discount: 0,
        departmentUuid: null,
        municipalityUuid: null
      },
      typeIndetificatios: ["Cédula de ciudadanía", "Pasaporte"],
      typeGenders: ["Femenino", "Masculino", "Otro"],
      departments: [],
      municipalities: [],
      repository: "authRepository",
    };
  },
  async mounted() {
    const me = this;
    me.loadDepartments();
    me.dateStarAndFinish();
  },
  validations: {
    formData: {
      names: {
        required,
      },
      last_names: {
        required,
      },
      email: {
        required,
        email,
      },
      type_identification: {
        required,
      },
      identification: {
        required,
        numeric,
      },
      cellphone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      cellphone_enterprise: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      name: {
        required,
      },
      nit: {
        required,
        numeric,
      },
      initial_date: {
        required,
      },
      final_date: {
        required,
      },
      total: {
        required,
      },
    },
  },
  methods: {
    dateStarAndFinish() {
      const me = this;
      let numberMonths = 1;

      me.formData.initial_date = moment().format("YYYY-MM-DD");

      me.formData.final_date = moment(me.formData.initial_date)
        .add(numberMonths, "months")
        .format("YYYY-MM-DD");
    },
    async loadDepartments() {
      const me = this;
      try {
        const { data } = await me.geographyRepository.getDepartments();
        me.departments = data;
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    async loadMunicipalitiesByDepart() {
      const me = this;
      me.formData.municipalityUuid = null;
      try {
        const { data } = await me.geographyRepository.getMunicipalitiesByDepartment(me.formData.departmentUuid);
        me.municipalities = data;
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
  },
};
</script>

<style lang="css">
.title-register {
  font-size: 20px;
  font-family: var(--fuente-primary);
  font-weight: bold;
  color: var(--black-color);
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>
