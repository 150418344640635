<template>
  <div>
    <Header />
    <div class="content-fist">
      <button-wpp />
      <section class="container mt-5 margin_bottom">
        <h1 class="title text_black">
          Optimiza tu negocio de tours con nuestros planes mensuales
        </h1>
        <h2 class="subtitle text_black">
          <strong>Elige tu plan ideal</strong> ¡Únete ahora y comienza a
          gestionar tus tours!
        </h2>
        <b-row class="mb-2" v-if="isBusy">
          <b-col lg="12" md="12" sm="12">
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Cargando datos...</strong>
            </div>
          </b-col>
        </b-row>
        <div class="content-cards" v-else>
          <card-plan
            v-for="plan in plans"
            :key="plan.uuid"
            :plan="plan"
            @selectPlan="selectPlan"
          />
        </div>
      </section>
    </div>
    <Footer />
    <base-modal :title="title" :id="id">
      <template v-slot:form>
        <form-register :plan="plan" @save="save" />
      </template>
    </base-modal>
    <ModalMessageSuccess />
  </div>
</template>

<script>
import Header from "@/app/home/components/Header";
import Footer from "@/app/home/components/Footer";
import CardPlan from "./components/CardPlan";
import BaseModal from "@/components/base/BaseModal";
import FormRegister from "./components/FormRegister";
import ModalMessageSuccess from "./components/ModalMessageSuccess";
import ButtonWpp from "@/components/buttons/ButtonWpp";
import { BRow, BCol, BSpinner } from "bootstrap-vue";
export default {
  inject: ["planEnterprisePriceRepository"],
  components: {
    Header,
    Footer,
    CardPlan,
    FormRegister,
    BaseModal,
    ModalMessageSuccess,
    ButtonWpp,
    BRow,
    BCol,
    BSpinner,
  },
  data() {
    return {
      plans: [],
      plan: null,
      title: "Formulario de Registro",
      id: "register-modal",
      isBusy: true,
    };
  },
  methods: {
    selectPlan(item) {
      const me = this;
      me.plan = item;
      me.$bvModal.show(me.id);
    },
    async loadPlans() {
      const me = this;
      try {
        const { data } = await me.planEnterprisePriceRepository.getAllByType(
          "Mensual"
        );
        data.sort((a, b) => {
          if (a.price > b.price) {
            return 1;
          }
          if (a.price < b.price) {
            return -1;
          }
          return 0;
        });
        data.forEach((el) => {
          me.plans.push({
            uuid: el.uuid,
            price: el.price,
            name: el.planEnterprise.name,
            numberTransportation: el.planEnterprise.number_transportations,
            numberUsers: el.planEnterprise.number_users,
          });
        });
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    save() {
      const me = this;
      me.$bvModal.hide(me.id);
      me.$bvModal.show("message-success");
    },
  },
  mounted() {
    window.scroll(0, 0);
    const me = this;
    me.loadPlans();
  },
};
</script>

<style lang="css">
@import url("../../home/home.css");

.card-plan {
  width: 400px !important;
  padding-bottom: 25px !important;
  cursor: pointer;
}

.content-cards .card-plan .title-card {
  text-align: center !important;
  font-weight: 500 !important;
}

.content-cards .card-plan .subtitle-card {
  text-align: center !important;
  font-size: 30px !important;
  font-weight: 600 !important;
}

.content-cards .card-plan h3 {
  text-align: center !important;
  font-size: 18px !important;
  color: var(--third-color) !important;
}
</style>
